// eslint-disable-next-line no-redeclare,no-unused-vars
function convertPattern(param) {
  var text = param.text;
  if (!text || typeof text != 'string') return '';
  var ident = hashCode(text);
  if (param.ident && param.ident == ident) return text;
  if (str_contains(text, '|frontend')) text = text.replace('|frontend', '');
  var row = param.row;
  var object = param.object;
  var matches = text.match(/__[a-z0-9.:=]+__/gi);
  var updating = [];

  $.each(matches, function (i, match) {
    var value = '';
    var calc = '';
    var variable = match.slice(2, -2);
    if (strpos(variable, '=') !== false) [variable, calc] = variable.split('=');
    else if (strpos(variable, ':') !== false)
      [variable, calc] = variable.split(':');

    var settings = null;
    if (row && isset(row[variable]))
      settings = getFieldSettings(param, variable);

    // pdf value
    if (
      settings &&
      !settings.value &&
      (settings.pdfValue || settings['pdfValue' + ucfirst(param.key)])
    ) {
      if (settings.pdfValue) settings.value = settings.pdfValue;
      else settings.value = settings['pdfValue' + ucfirst(param.key)];
    }

    if (
      settings &&
      settings['adoptValues' + ucfirst(param.key)] &&
      !param.second
    ) {
      value =
        settings['adoptValues' + ucfirst(param.key)][
          settings.values.indexOf(row[variable])
        ];
    } else if (
      settings &&
      settings.value &&
      isArray(settings.value) &&
      row[variable] &&
      settings.value[settings.values.indexOf(row[variable])] &&
      !str_contains(
        settings.value[settings.values.indexOf(row[variable])],
        variable
      )
    ) {
      value = settings.value[settings.values.indexOf(row[variable])];
    } else if (settings && settings.value && isPlainObject(settings.value)) {
      value = settings.value[row[variable]];
    } else if (settings) {
      value = row[variable];
      if (param.key && variable == param.key && param.value)
        value = param.value;
      if (isArray(value) && typeof param.index !== 'undefined')
        value = value[param.index];
      if (!calc && variable != 'short' && !param.calc) {
        var pm = Object.assign({}, param, {
          value: value,
          settings: settings,
          row: row,
          exact: true,
          key: variable,
        });
        if (param.key) pm.readValue = param.key;
        value = convert(pm);
      }
      // if (param.element) {
      // 	if (!getObjectValue(elements, [object, row.id, variable + '_connected'])) setObjectValue(elements, [object, row.id, variable + '_connected'], []);
      // 	if (!inArray(param.element, elements[object][row.id][variable + '_connected'])) elements[object][row.id][variable + '_connected'].push(param.element);
      // }
    } else if (variable == 'url') value = document.location.origin;
    else if (variable == 'client') value = client;
    else if (variable == 'host') value = document.location.host;
    else if (variable == 'endpoint') value = endpoint;
    else if (variable == 'window') value = windowId;
    else if (variable == 'user') value = user.short;
    else if (variable == 'brand') value = store.short;
    else if (variable == 'today') value = moment().format('YYYY-MM-DD');
    else if (strpos(variable, '.') !== false) {
      const [key, refKey] = variable.split('.');
      if (key == 'user') value = user[refKey];
      else if (key == 'store' && !object) value = store[refKey];
      else if (object && fields[object][key] && row) {
        var refObj = fields[object][key].reference;
        var callback = false;
        if (param.update && !inArray(key, updating)) {
          callback = function () {
            requestIdleCallback(function () {
              setData(
                param.update.object,
                param.update.id,
                param.update.key,
                convertPattern(param),
                false,
                false,
                true
              );
            });
          };
          updating.push(key);
        }
        value = getData(
          refObj,
          row[key],
          refKey,
          false,
          param.element,
          callback
        );
        if (isArray(value))
          value = convert({value: value, object: refObj, key: refKey});
      } else if (
        object &&
        !fields[object][key] &&
        fields[object][refKey] &&
        row
      )
        value = row[refKey];
      if (value == '...' && param.emptyOnMissingVar) text = '';
    }
    if (calc && value) {
      if (isNumber(value)) value = value.toString();
      if (calc == 'translit')
        value = value
          .normalize('NFKD')
          .replace(/[\u0300-\u036F]/g, '')
          .toLowerCase();
      else if (calc == 'label') {
        value = settings.labels[settings.values.indexOf(value)];
        // if (!defined(value)) value = end(settings.labels);
      } else if (calc == 'date') value = moment(value).format('DD.MM.YYYY');
      else if (calc == 'year') value = moment(value).format('YYYY');
      else if (calc == 'year2') value = moment(value).format('YY');
      else if (calc == 'month') value = moment(value).format('MM');
      else if (calc == 'day') value = moment(value).format('DD');
      else if (calc == 'number') value = value.replace(/[^0-9]/g, '');
      else if (calc == 'read') value = row[variable + '_read'];
      else if (strpos(calc, 'last') === 0)
        value = value.slice(calc.slice(4) * -1);
      else if (strpos(calc, 'first') === 0)
        value = value.slice(0, calc.slice(5));
    }
    if (value === null) value = '';
    if (param.encode) value = encodeURIComponent(value);
    text = text.replace(new RegExp(escapeRegExp(match)), value);
  });
  text = text.replace(/[ ]+/, ' ');
  text = text.replace('\\n', '\n');
  if (text.match(/__[a-z0-9_.:]+__/gi))
    text = convertPattern(Object.assign({}, param, {text: text, ident: ident}));
  return text;
}
